import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Courier Management Software',
        projectDesc: 'Courier Management Software',
        tags: ['Node','React'],
        code: '#',
        demo: 'https://flybeecouriersoftware.com',
        image: six
    },
    {
        id: 2,
        projectName: 'POS',
        projectDesc: 'Point of Sale',
        tags: ['Laravel','Mysql'],
        code: '#',
        demo: 'http://software.waterfinebd.com',
        image: six
    },
    {
        id: 3,
        projectName: 'Boi Bichitra Software',
        projectDesc: 'This project for stock manage of store',
        tags: ['Laravel', 'React', 'Material Ui'],
        code: '#',
        demo: 'https://boibichitraerp.xyz/login',
        image: one
    },
    {
        id: 4,
        projectName: 'Boi Bichitra Ecommerce',
        projectDesc: 'This project for E-commerce application',
        tags: ['Laravel', 'Mysql'],
        code: '#',
        demo: 'https://boibichitra.com/',
        image: one
    },
    {
        id: 5,
        projectName: 'Fabric',
        projectDesc: 'Buyer and seller product buy and sell in online platform',
        tags: ['Laravel', 'MySQL'],
        code: '#',
        demo: 'https://fabriclagbe.com',
        image: two
    },
    {
        id: 6,
        projectName: 'Hospital Management System',
        projectDesc: 'Doctor can see patient in online and create prescription.',
        tags: ['Laravel', 'MySQL'],
        code: '#',
        demo: 'https://priyojon.care/',
        image: three
    },
    {
        id: 7,
        projectName: 'Daily Service',
        projectDesc: 'All services are hold in this site',
        tags: ['React','Laravel'],
        code: '#',
        demo: 'https://dailyservice.net/',
        image: four
    },
    {
        id: 8,
        projectName: 'E-Commerce App',
        projectDesc: 'A Simple E-commerce application',
        tags: ['Laravel', 'MySQL'],
        code: '#',
        demo: 'https://bbazar.com.bd',
        image: five
    },
    {
        id: 9,
        projectName: 'BD FOOD',
        projectDesc: 'BD FOOD Soudi Arabic',
        tags: ['Laravel','MySQL'],
        code: '#',
        demo: 'http://demodistribution.starittech.xyz/',
        image: six
    },
    {
        id: 10,
        projectName: 'Distribution',
        projectDesc: 'Distribution Management',
        tags: ['Laravel','React'],
        code: '#',
        demo: 'https://agarh.net/login',
        image: six
    },
    {
        id: 11,
        projectName: 'Online Service',
        projectDesc: 'Online Service',
        tags: ['Laravel', 'MySQL'],
        code: '#',
        demo: 'https://doctorpathao.com/',
        image: five
    },
    {
        id: 12,
        projectName: 'Company Website',
        projectDesc: 'Trikon Architechs',
        tags: ['Laravel','React'],
        code: '#',
        demo: 'http://trikonarchitects.com/',
        image: six
    },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/