export const socialsData = {
    github: '#',
    facebook: '#',
    linkedIn: '#',
    instagram: '#',
    codepen: '#',
    twitter: '#',
    reddit: '#',
    blogger: '#',
    medium: '#',
    stackOverflow: '#',
    gitlab: '#',
    youtube: '#'
}