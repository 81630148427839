export const educationData = [
    {
        id: 1,
        institution: 'Ahmedpur College-2',
        course: 'Higher Secondary Education',
        startYear: '2007',
        endYear: '2009'
    },
    {
        id: 2,
        institution: 'Dhaka International University',
        course: 'B. Sc in CSE',
        startYear: '2010',
        endYear: '2014'
    },
]