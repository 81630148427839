export const blogData = [
    {
        id: 1,
        title: 'Blog Title 1',
        description: 'Here we will use Selenium to navigate between web pages, and try to scrape data from them.',
        date: 'Aug 7, 2020',
        image: 'https://1.bp.blogspot.com/-6vZQoWcIZeo/Xvw5ZD5Y53I/AAAAAAAABgI/GH2b5OuMaXAKuigK4diBbeYDW6dD3qVvgCK4BGAsYHg/w400-h225/selenium.jpg',
        url: '#'
    },
    {
        id: 2,
        title: 'Blog Title 2',
        description: 'wttr.in is a console-oriented weather forecast service that supports various information representation methods like ANSI-sequences for console HTTP clients, HTML, or PNG.',
        date: 'Aug 11, 2020',
        image: 'https://1.bp.blogspot.com/-OW7jX57tea4/XvnGxuEOslI/AAAAAAAABW0/R8lVT1AXDSwnvE0EGA9Ra49-LDm1ACwDgCK4BGAsYHg/s1216/wttr1.png',
        url: '#'
    },
    {
        id: 3,
        title: 'Blog Title 3',
        description: 'Here are some basic ways to lay off the Radar',
        date: 'Aug 14, 2020',
        image: 'https://1.bp.blogspot.com/-GR19yCNbKCE/XuRcKEfKvhI/AAAAAAAAAwY/srFWw5pOpzMibeJb__SmgZpx9dIpNNWqgCK4BGAsYHg/w640-h426/anonymous1.jpg',
        url: '#'
    },
    {
        id: 4,
        title: 'Blog Title 4',
        description: 'Want to check your internet speed from the terminal? Here\'s the perfect way to the that.',
        date: 'Sep 17, 2020',
        image: 'https://1.bp.blogspot.com/-XTVMsZP3HaM/Xxpl394txlI/AAAAAAAABws/zovXWiuNGg4TzBwYYVFfJ1ixDb3JfVokgCNcBGAsYHQ/w400-h195/speedtest.png',
        url: '#'
    },
    {
        id: 5,
        title: 'Blog Title 5',
        description: 'In case you haven\'t heard of this game, GTA stands for \'Grand Theft Auto\'. It is an open-world game published by Rockstar Games.',
        date: 'Oct 1, 2020',
        image: 'https://1.bp.blogspot.com/-jhdtUBIRD_s/XxLFKQuRz9I/AAAAAAAABsM/mClfbfDFKvsUwA7Wyi25Lzu3DEmL2lxrACNcBGAsYHQ/s1919/gta.jpg',
        url: '#'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/