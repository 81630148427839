export const experienceData = [
    {
        id: 1,
        company: 'Windmill Infotech Ltd.',
        jobtitle: 'Web Developer',
        startYear: '2016',
        endYear: '2019'
    },
    {
        id: 2,
        company: 'StarIT Ltd.',
        jobtitle: 'Full Stack Developer',
        startYear: '2019',
        endYear: 'Running'
    },
]