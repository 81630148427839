import resume from '../assets/pdf/resume.pdf'
import landingImg from '../assets/png/landingImg.png'

export const headerData = {
    name: 'Robiul Islam',
    title: "Full Stack Developer",
    desciption:"I am a highly competent IT professional with a proven track record in designing websites and managing databases. I have strong technical skills as well as excellent interpersonal skills, enabling me to interact with a wide range of clients. I am eager to be challenged in order to grow and further improve my IT skills. My greatest passion is in life is using my technical know-how to benefit other people and organisations. ",
    image: landingImg,
    resumePdf: resume
}
